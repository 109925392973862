
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

import { IContextMenuItem } from '@/components/context-menu/model';

import ContextMenuSpawner from '@/components/context-menu/ContextMenuSpawner.vue';
import Button from '@/components/ui/button';
import DocumentMeta from './DocumentMeta.vue';
import ButtonGroup from '@/components/ui/button-group';
import Label from '@/components/ui/label';
import Icon from '@/components/ui/icon/Icon.vue';
import FindOrCreate from '@/components/context-menu/repeatable/FindOrCreate.vue';
import TagsModule from '@/store/modules/tags/TagsModule';
import Editor from '@/modules/settings/views/directions/components/Editor.vue';
import { Colors } from '@/modules/settings/views/directions/model';
import { newRandomColor, createTag } from '@/modules/settings/views/directions/Directions.vue';

@Component({
  name: 'DocumentMetaDirections',
  components: { DocumentMeta, Button, ButtonGroup, Label, Icon, ContextMenuSpawner, FindOrCreate, Editor },
})
export default class DocumentMetaDirections extends Vue {
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: (): Array<number> => [] }) selected: number[];

  public editorHeader = '';
  public editorFunction: Function = null;
  public showEditor = false;
  public Colors = Colors;
  public showAddItem = false;
  public items: Array<IContextMenuItem> = [];

  public get tags() {
      return TagsModule.tags;
  }

  allDirections()
  {
    const selected = this.tags.map((el) => el.id);
    this.change(selected);
  }
  get selectedTags() 
  {
    return this.tags.filter(tag => this.selected.includes(tag.id));
  }
  removeAllDirections()
  { 
    this.change([]);
  }
  async created() {
    await this.initPage();
  }

  async initPage() {
    await TagsModule.fetchTags();
    this.onLoaded();
  }

  @Emit('pageLoaded')
  onLoaded() {
    return true;
  }

  @Emit('change')
  change(val: number[]) {
    return val;
  }

  openEditor(heading: string, func: Function) {
      this.editorHeader = heading;
      this.editorFunction = func;
      this.showEditor = true;
  }
  closeEditor() {
        this.showEditor = false;
    }
  createNewDirection(newName: { name: string }) {
      createTag({name: newName.name, color: newRandomColor()})
      this.closeEditor();
  }

  onSelect(id: number) {
    const selected = this.selected;
    selected.push(id);

    this.change(selected);
  }

  remove(index: number) {
    const selected = this.selected;
    selected.splice(index, 1);

    this.change(selected);
  }
}
