
import { Component, Emit, Vue, Prop, Watch } from 'vue-property-decorator';
import DotsIcon from '@/assets/images/dots.svg';
import ContextMenuLink from '@/components/context-menu/items/ContextMenuLink.vue';
import ContextMenuSpawner from '@/components/context-menu/ContextMenuSpawner.vue';
import { DocumentName } from './model';
import DeleteContext from '@/components/context-menu/repeatable/DeleteContext.vue'
import Icon from '@/components/ui/icon/Icon.vue';
import ApiClient from '@/api/api.client';
import Notify from '@/services/helpers/notify';
import KnowledgeModule from '@/store/modules/knowledge/KnowledgeModule';
import UsersModule from '@/store/modules/users/UsersModule';
@Component({
	name: 'DocumentSummary',
	components:
	{
		ContextMenuSpawner,
		ContextMenuLink,
		Icon,
		DeleteContext,
	},
})
export default class DocumentSummary extends Vue
{
	@Prop({
		default:
		{
			title: '',
			subtitle: '',
		},
	})
	value: DocumentName;
	public dotsIcon = DotsIcon;
	@Prop({
	default: () => ({
		title: 'Documents.Document.TitlePlaceholder',
		subtitle: 'Documents.Document.SubtitlePlaceholder',
		}),
	})
	placeholders: DocumentName;

	@Prop({ default: false }) readonly: boolean;
	@Prop({ }) type: string;

	public inputValue = ''
	public title = '';
	public showDeleteContext = false;
	public hasNameOption = false;
	public isOptionsDocumentVisible = false;
	public subtitle = '';
	private innerTitle: string;

	public created(): void
	{
		this.title = this.value.title;
		this.subtitle = this.value.subtitle;
	}

	mounted()
	{
		const areaEl = this.$refs.documentNameArea as HTMLElement
		this.$nextTick(() =>
		{
			areaEl.focus();
			this.autoSize(areaEl)
		})
	}
	@Watch('type')
	typeChanged(val: string)
	{
		const areaEl = this.$refs.documentNameArea as HTMLElement
		this.$nextTick(() =>
		{
			areaEl.focus();
		})
	}

	get userCanEdit()
	{
		return UsersModule.currentUser.admin ||
		UsersModule.currentUser.permitions.knowledge.manage
	}

	onDocumentNameChanged()
	{
		if(this.title.length > 50)
		{
			Notify.error('Максимальное количество симоволов 50')
			this.title = this.title.slice(0, 50)
		}
	}
	autoSize(ref:any)
	{
		const element = ref.target ? ref.target : ref;

		this.onDocumentNameChanged()

		if(window.innerWidth < 961)
		{
			if(element.className === 'document-name__title')
			{
				element.style.height = '10px';
			}
			else
			{
				element.style.height = '14px';
			}
		}
		else
		{
			element.style.height = '54px';
		}
		element.style.height = element.scrollHeight + 'px';
	}

	@Emit('editKnowledge')
	public editKnowledge():boolean
	{
		return true;
	}
	optionNameClicked()
	{
		this.isOptionsDocumentVisible = !this.isOptionsDocumentVisible;
		this.hasNameOption = !this.hasNameOption;
	}
	hideOptionsContent()
	{
		this.isOptionsDocumentVisible = false;
		this.hasNameOption = false;
	}
	async deleteItem()
	{
		const id = Number(this.$route.params.documentId)
		ApiClient.knowledge.deleteKnowledge(id).then(() =>
		{
			Notify.success('Знание удалено');
			KnowledgeModule.fetchKnowledge();
		}).finally(() =>
		{
			this.$router.push('/')
		});
	}

	openDeleteContext()
	{
		this.showDeleteContext = true;
	}

	closeDeleteContext()
	{
		this.showDeleteContext = false;
	}

	@Emit('input')
	public onInput(): DocumentName
	{
		return {
			title: this.title,
			subtitle: this.subtitle,
		};
	}

	public onChange(): void
	{
		this.$nextTick(this.onInput.bind(this));
	}
}
