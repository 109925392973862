
  import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
  
  import { IContextMenuItem } from '@/components/context-menu/model';
  
  import ContextMenuSpawner from '@/components/context-menu/ContextMenuSpawner.vue';
  import Button from '@/components/ui/button';
  import DocumentMeta from '../DocumentMeta.vue';
  import ButtonGroup from '@/components/ui/button-group';
  import Label from '@/components/ui/label';
  import Icon from '@/components/ui/icon/Icon.vue';
  import { IdentifiedItem, NamedItem } from '@/models/inputs';
  import FindOrCreate from '@/components/context-menu/repeatable/FindOrCreate.vue';
  
  import ProjectsModule from '@/store/modules/projects/ProjectsModule';
  
  @Component({
    name: 'DocumentMetaProjectsKnowledge',
    components: { DocumentMeta, Button, ButtonGroup, Label, Icon, ContextMenuSpawner, FindOrCreate },
  })
  export default class DocumentMetaDirections extends Vue {
    @Prop({ default: false }) disabled: boolean;
    @Prop({ default: (): Array<number> => [] }) selected: number[];


    public showAddItem = false;
    public items: Array<IContextMenuItem> = [];
    public commonKnowledge: NamedItem & IdentifiedItem = {} as null;
    public clientProjects: Array<NamedItem & IdentifiedItem> = [];
  
    get selectedProjects() {
      return this.clientProjects.filter(project => this.selected.includes(project.id));
    }
  
    async created() {
      await this.initPage();
    }
  
    async initPage() {
      await ProjectsModule.fetchProject(Number(this.$route.params.projectId)).finally(async() => 
      {
        await ProjectsModule.fetchClientProjects(this.project.client_id).finally(() => {
          this.clientProjects = this.allClientProjects
        });
      })
      this.onLoaded();
    }
    
    get project()
    {
      return ProjectsModule.clientProject;
    }

    onSelectCommonKnowledge(item: NamedItem & IdentifiedItem)
    {
      this.commonKnowledge = item;
      this.onSelect(item.id)
    }

    get allClientProjects()
    {
      return ProjectsModule.allClientProjects
    }

    @Emit('pageLoaded')
    onLoaded() {
      return true;
    }
  
    @Emit('changeProjectName')
    change(val: number[]) {
      return val;
    }
  
    onSelect(id: number) {
      const selected = this.selected;
      selected.push(id);
      
      this.change(selected);
    }
  
    remove(item: NamedItem) {
      let selected = this.selected;
      selected = selected.filter((el) => el != item.id);

      this.change(selected);
    }
  }
  