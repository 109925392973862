var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"documents-dashboard"},[_c('div',{staticClass:"container"},[(_vm.marks && _vm.listMarks)?_c('DocumentsHeader',{attrs:{"marks":_vm.marks,"listMarks":_vm.listMarks,"roles":_vm.rolesArray,"count":_vm.count},on:{"changeMark":_vm.changeMarkChecked,"uncheckAllMarks":_vm.uncheckAllMarks}}):_vm._e(),_c('Preloader',{attrs:{"loading":_vm.isLoading}}),[(_vm.showEmpty)?_c('EmptyBackplate',{style:({'opacity': !_vm.isLoading ? '1' : '0',
					'ponter-events': !_vm.isLoading ? 'all' : 'none'}),attrs:{"to":"/documents/create","message":"Еще не добавлено ни одного знания"}}):_vm._e()]],2),[_vm._l((_vm.knowledges),function(data){return _c('DocumentsCanban',{key:data.id,style:({
				'opacity': !_vm.isLoading ? '1' : '0',
				'pointer-events': !_vm.isLoading ? 'all' : 'none'
			}),attrs:{"data":data,"spec_id":data.spec_id,"specializations":_vm.rolesArray,"expandedList":_vm.expandedList[data.spec_id]},on:{"expandList":function($event){return _vm.expandList(data.spec_id, $event)},"onDrop":_vm.fetchKnowledges,"deleteItem":_vm.fetchKnowledges,"subcategoryUpdate":_vm.fetchKnowledges}})}),(_vm.knowledges && _vm.knowledges.for_all)?_c('DocumentsCanban',{style:({
				'opacity': !_vm.isLoading ? '1' : '0',
				'pointer-events': !_vm.isLoading ? 'all' : 'none'
			}),attrs:{"data":{ items: _vm.knowledges.for_all },"spec_id":_vm.knowledges.for_all,"title":'Для всех'},on:{"onDrop":_vm.fetchKnowledges,"deleteItem":_vm.fetchKnowledges}}):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }