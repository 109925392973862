<template>
    <node-view-wrapper class="vue-component">
        <div class="content HFWUpdEUGucAGYuPLrSEwRglGgLKcz">
            <div class="fileloader-wrp" v-if="mode != 'view' && slides.length === 0"
                :class="{ 'fileloader-show': mode === 'edit' || mode === 'create' }">
                <span class="fileloader-descr">Выберите изображение (вы можете прикрепить несколько в галлерею)</span>
                <label :for="swiperName" class="fileloader-label" >
                    Обзор
                </label>
            </div>

            <input type="file" multiple :id="swiperName" @input="loadImg($event)" v-show="false" />
            <div @click="closePopup" :class="{ 'popup-wrapper': isPopupOpen }">
                <div :class="{ 'popup-content': isPopupOpen }">
                    <swiper v-if="loaded && showSlider" :id="`slider${swiperName}`" :options="swiperOption"
                        class="swiper-container--single-slide" :ref="swiperName" @slideChange="slideChanged">
                        <swiper-slide v-for="(item, index) in slides" :key="index + item.src">
                            <div class="slide-wrapper">
                                <div class="picture__controls" v-if="mode != 'view'">
                                    <label class="addImg" :for="swiperName">
                                        <Icon name="plus" size="12" />
                                    </label>
                                    <button class="deleteImg" @click="deleteEl(index)">
                                        <Icon name="cross" size="12" />
                                    </button>
                                </div>
                                <img @click.stop="openPopup" :src="item.src" alt="" class="img-full-slider">
                                <textarea placeholder="Подпись" @input="setInputValue" v-model="item.title"
                                    class="image-label" v-if="mode != 'view'" />
                                <div class="image-label-wrp" v-else-if="mode === 'view' && item.title.length">
                                    {{ item.title }}
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>

                    <swiper class="swiper-container--shifted" v-show="slides.length > 1 && mode !== 'view'"
                        :options="swiperThumbsOption" :ref="`${swiperName}Thumbs`">
                        <swiper-slide v-for="(item, index) in slides" :key="index + item.src">
                            <div class="slide-wrapper">
                                <div class="picture__controls" v-if="mode != 'view'">
                                    <button class="deleteImg" @click="deleteEl(index)">
                                        <Icon name="cross" size="12" />
                                    </button>
                                </div>
                                <img :src="item.src" class="img-small-slider" alt=""
                                    @click.stop="openPopup; goToSlide(index) ">

                                <textarea placeholder="Подпись" @input="setInputValue" v-model="item.title"
                                    class="image-label" v-if="mode != 'view'" />
                                <div class="image-label-wrp" v-else-if="mode === 'view' && item.title.length">{{ item.title
                                }}</div>
                            </div>
                        </swiper-slide>
                    </swiper>

                    <div :class="swiperScrollName" :id="swiperScrollName" class="swiper-scrollbar" slot="scrollbar"
                        v-if="mode != 'view' && pagloaded"></div>
                    <div :class="`${swiperName}-swiper-pagination ${swiperPaginationName}`" :id="swiperPaginationName"
                        class="swiper-pagination" slot="pagination"
                        ref="pagination"
                        v-if="mode === 'view' && slides.length > 1 && pagloaded"></div>
                    <div v-show="mode === 'view' && slides.length > 1" class="swiper-button-prev"
                        :class="{ 'swiper-button--disabled': arrows.isLeftHidden }"
                        @click.stop="$refs[swiperName].swiperInstance.slidePrev()">
                        <Icon name="iconly/arrow-left2" :size="31" />
                    </div>
                    <div v-if="mode === 'view' && slides.length > 1" class="swiper-button-next"
                        :class="{ 'swiper-button--disabled': arrows.isRightHidden }"
                        @click.stop="$refs[swiperName].swiperInstance.slideNext()">
                        <Icon name="iconly/arrow-right2" :size="31" />
                    </div>
                </div>
            </div>

            <!-- заглушка убирающия сдвиги при открытии полноэкранного слайдера -->
            <div :style="{ height: sliderNodeHeight + 'px' }" v-if="isPopupOpen" />

            <div v-if="mode != 'view' && slides.length > 1 && !$isMobile" class="swiper__add-slide">
                <label :for="swiperName">
                    <Icon name="plus" size="17" />
                </label>
            </div>
        </div>
    </node-view-wrapper>
</template>

<script>
import axios from 'axios';
import { HEADERS } from '@/services/helpers/constants';
import Cookies from 'js-cookie';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import Icon from '@/components/ui/icon/Icon.vue';

import Mode from '@/store/modules/mode/mode';
import { MAIN_API_HOST } from '@/services/helpers/constants';
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-2';
import Notify from '@/services/helpers/notify';

export default {
    components: {
        Swiper,
        Icon,
        SwiperSlide,
        NodeViewWrapper,
    },
    props: {
        nodeViewProps,
        editor: {
            type: Object,
        },
        node: {
            type: Object,
        },
        updateAttributes: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            isPopupOpen: false,
            mode: '',
            currentSlide: 0,
            pagloaded: false,
            loaded: false,
            swiperScrollName: 'scrollbar',
            swiperPaginationName: 'pagination',
            slidrSlides: [],
            swiperName: '1',
            showSlider: true,
            fillerHeight: 0,
        };
    },
    beforeMount() {
        this.mode = Mode.mode;
        this.swiperName = this.makeid(30);
        this.swiperScrollName += this.swiperName
        this.swiperPaginationName += this.swiperName
        if (this.mode != 'view') {
            this.swiperOption.scrollbar = { el: `.${this.swiperScrollName}` };
        }
        this.swiperOption.pagination.el = `.${this.swiperPaginationName}`;
    },
    mounted() {
        this.slidrSlides = this.node.attrs.content;
        this.$nextTick(() => {
            this.pagloaded = true;
            this.$nextTick(async () => {
                this.loaded = true;
            })
        });
    },
    computed: {
        sliderNodeHeight() {
            const node = document
                .querySelector(`#slider${this.swiperName}`)
                .getBoundingClientRect().height;
            return node;
        },
        swiperOption() {
            // const slidesLength = this.slides?.length;
            this.$nextTick();

            const options = {
                notNextTick: true,
                watchOverflow: true,
                // autoHeight: true,
                observer: true,
                observeParents: true,
                // mousewheelControl: true,
                // mousewheel: true,
                spaceBetween: 30,
                slidesPerView: 1,
                // loop: true,
                // loopedSlides: 4,
                navigation: {
                    nextEl: `.${this.swiperName} .swiper-button-next`,
                    prevEl: `.${this.swiperName} .swiper-button-prev`,
                },
                pagination:
                {
                    el: `.${this.swiperName}-swiper-pagination`,
                    clickable: true,
                },
                scrollbar: {
                    el: `.${this.swiperScrollName}`,
                },
            };

            return options;
        },
        swiperThumbsOption() {
            const thumbsOptions = {
                spaceBetween: 10,
                centeredSlides: true,
                slidesPerView: 'auto',
                touchRatio: 1,
                slideToClickedSlide: true,
                resizeObserver: false,
                // loop: true,
                loopedSlides: 4,
                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 2,
                        spaceBetween: 7,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 31,
                    },
                },
            };

            return thumbsOptions;
        },
        slides() {
            if (this.slidrSlides) {
                return this.slidrSlides;
            }
            return [];
        },
        swiper() {
            return this.$refs[this.swiperName].$swiper;
        },
        swiperThumbs() {
            return this.$refs[`${this.swiperName}Thumbs`].$swiper;
        },
        arrows() {
            const active = this.currentSlide;
            const slidesCount = this.slides.length;
            const options = {
                isLeftHidden: active === 0 ? true : false,
                isRightHidden: active + 1 === slidesCount ? true : false,
            };
            return options;
        },
    },
    methods: {
        goToSlide(index) {
            this.swiper.slideTo(index);
            this.swiperThumbs.slideTo(index);
        },
        openPopup() {
            if (Mode.mode === 'view') {
                this.isPopupOpen = true;
                this.calcPaginationPos()
            }
        },
        closePopup(e) {
            const target = e.target.className?.includes('swiper-pagination-bullet');
            this.$refs.pagination ? this.$refs.pagination.style.top = '' : null
            if (Mode.mode === 'view' && !target) {
                this.isPopupOpen = false;
            }
        },
        calcPaginationPos() {
            setTimeout(() => { this.$refs.pagination.style.top = document.querySelectorAll('.img-full-slider')[this.currentSlide].offsetHeight + 11 + 'px' });
        },
        slideChanged() {
            this.currentSlide = this.$refs[this.swiperName].$swiper.activeIndex;
            const sliderLabels = document.querySelectorAll('.swiper-slide')
            this.slide = sliderLabels[this.currentSlide]
            if (this.isPopupOpen)
                this.calcPaginationPos();
        },
        isImageValid(type) {
            const allowedTypes = ['/png', '/jpeg', '/webp', '/gif'];
            let isAllowed = false;
            allowedTypes.forEach(el => {
                if (type.type.indexOf(el) >= 1) {
                    isAllowed = true;
                }
            });
            return isAllowed;
        },
        makeid(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
            const charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },
        deleteEl(index) {
            this.slidrSlides = this.slidrSlides.filter(el => el != this.slidrSlides[index]);
            this.saveDoc();
        },
        setInputValue() {
            this.saveDoc();
        },  
        loadImg(event) {
            const imgArray = Array.from(event.target.files)
            this.slidrSlides = this.slidrSlides || [];
            imgArray.forEach(async element => {
                if (this.isImageValid(element)) {
                    this.slidrSlides.push({ src: await this.fileUploader(element), title: '' });
                    this.saveDoc();
                }
                else {
                    Notify.error('Данный формат не поддеживается')
                }
            });
            console.log(this.editor.commands);
            
            this.editor.commands.focus('all')
            setTimeout(() =>
            {
                this.$refs[this.swiperName].$swiper.controller.control = this.$refs[`${this.swiperName}Thumbs`].$swiper;
                this.$refs[`${this.swiperName}Thumbs`].$swiper.controller.control = this.$refs[this.swiperName].$swiper;
            }, 1000);            
        },
        saveDoc() {
            this.updateAttributes({ content: this.slidrSlides });
        },
        async fileUploader(files) {
            const formData = new FormData();
            formData.append('file', files);
            const result = await axios.post(`${MAIN_API_HOST}/filesystem/store`, formData, {
                authorization: `Bearer ${Cookies.get(HEADERS.AUTH_HEADER)}`,
            });
            return result.data.file.url;
        },
    },
    watch: {
        async slides(upd, prev) {
            const prevL = prev.length;
            const updL = upd.length;

            if ((prevL === 1 && updL === 2)
                || (prevL === 2 && updL === 1)
                || (prevL === 2 || prevL === 1 && updL === 2 || updL === 1)) {
                if (this.$refs[this.swiperName]?.$swiper) {
                    this.showSlider = false;
                    await this.$nextTick();
                    this.showSlider = true;
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.pulse-document__main-mobile {
    .img-full-slider {
        height: 222px;

        @include on-tablet {
            height: 350px;
        }
    }
}

.image-label-wrp {
    position: relative;
    left: 0;
    right: 0;
    text-align: center;
    font-family: $font-family;
    font-style: normal;
    font-weight: 100;
    font-size: 14px;
    line-height: 17px;
    margin-top: 30px;
    text-align: center;
    color: rgba(221, 221, 221, 0.7);
}

.swiper-scrollbar {
    height: 3px;
    background-color: rgba(62, 62, 62, 0.7);

    &::v-deep {
        .swiper-scrollbar-drag {
            background-color: rgba(112, 110, 110, 0.7);
        }
    }
}

.swiper-pagination {
    position: absolute;
    top: 482px;
    margin-top: 10px;

    &::v-deep {
        .swiper-pagination-bullet {
            width: 6px;
            height: 6px;
            margin: 0 4px;
            background: none;
            border: 1px solid #656565;
        }

        .swiper-pagination-bullet-active {
            background: #C4C4C4;
            border: 1px solid #C4C4C4;
        }
    }
}

.img-small-slider {
    width: 100%;
    height: 185px;
    object-fit: cover;

    @include on-mobile {
        height: 100px;
    }
}

.fileloader-wrp {
    display: none;
    margin-bottom: 10px;
}

.fileloader-show {
    display: block;
}

.swiper-button-prev,
.swiper-button-next {
    top: 46%;
    color: #fff;
}

.swiper-button--disabled {
    color: rgba(255, 255, 255, 0.5);
}

.swiper-button-prev {
    left: -40px;
}

.swiper-button-next {
    right: -40px;
}

.fileloader-label {
    cursor: pointer;
    background: #F7D547;
    border-radius: 8px;
    padding: 9px 14px;
    font-family: 'Museo Sans Cyrl';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 144%;
    font-family: $font-family;
    color: #000000;
}

.fileloader-label__preview {
    font-family: $font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 144%;
    color: #000000;
    height: 30px;
    width: 60px;
    text-align: center;
    border-radius: 8px;
    background: #F7D547;
}

.image-label {
    width: 100%;
    margin-top: 8px;
    background: none;
    outline: none;
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    line-height: 120%;
    font-family: $font-family;
    color: #DDDDDD;

    resize: none;

    &::placeholder {
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        color: rgba(221, 221, 221, 0.5);
    }
}

.fileloader-descr {
    display: block;
    margin-bottom: 20px;
}

.img-full-slider {
    width: 100%;
    height: 450px;
    max-height: 617px;
    object-fit: cover;
}

.swiper-slide {
    padding-top: 17px;
    transition: 0.25s ease-in-out 0.2s;

    cursor: pointer;

    &:active {
        cursor: grabbing;
    }
}

.swiper__add-slide {
    position: absolute;
    bottom: 160px;
    right: -59px;

    width: 48px;
    height: 48px;

    background-color: #2C2C2C;
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    border-radius: 9px;
    box-sizing: border-box;
    overflow: hidden;


    label {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;

        transition: 0.25s ease-in-out;
        cursor: pointer;
    }

    .icon {
        color: rgba(255, 255, 255, 0.8);
        transition: 0.25s ease-in-out;
    }

    &:hover {
        label {
            background-color: #333;
        }

        .icon {
            color: #fff;
        }
    }

    @include on-mobile {
        top: 50px;
        right: 0;

        width: 32px;
        height: 32px;
    }
}

.swiper-pagination {
    width: 100%;
}

.deleteImg {
    cursor: pointer;
    position: absolute;
    right: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    color: rgb(102, 102, 102);
    background: #2C2C2C;
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
}

.content {
    position: relative;

    @include on-mobile {
        margin-top: 0.75em;
    }

    .picture__controls {
        position: absolute;
        top: 0;
        right: 3px;

        height: 34px;
        display: flex;

        background: #2C2C2C;
        border: 0.5px solid rgba(255, 255, 255, 0.2);
        border-radius: 9px;
        box-sizing: border-box;

        .addImg,
        .deleteImg {
            position: static;
            height: 100%;
            padding: 0 13px;
            border: 0;
            flex-basis: 50%;
            border-radius: 9px;

            transition: 0.25s ease-in-out;

            .icon {
                transition: 0.25s ease-in-out;
            }

            &:hover {
                background-color: #333;
            }

            &:active {
                .icon {
                    transform: scale(0.8);
                }
            }
        }

        .addImg {
            display: flex;
            align-content: center;
            justify-content: center;

            color: rgba(255, 255, 255, 0.8);

            cursor: pointer;
        }

        .deleteImg {
            color: #9E4835;
        }
    }

    .swiper-container {
        padding-bottom: 60px;

        &--shifted {
            right: 53px;
            width: 812px;

            @include on-mobile {
                right: 0;
                width: 100%;
            }

            @include on-tablet {
                right: 32px;
                width: 100%;
            }
        }

        &::v-deep .swiper-wrapper {
            textarea {
                scrollbar-color: rgba(112, 110, 110, 0.7) transparent;
                scrollbar-width: thin;

                &::-webkit-scrollbar {
                    position: relative;
                    width: 3px;
                    background-color: transparent;
                    z-index: 3;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: rgba(112, 110, 110, 0.7); 
                    border-radius: 3px;
                }
            }
        }
    }



    .swiper-container--single-slide {
        max-width: 773px;
        right: 0;

        &::v-deep .swiper-wrapper {
            position: relative;
            width: fit-content;
            display: flex;

            .swiper-slide {
                display: flex;
                justify-content: center;
                transition: 0.25s ease-in-out 0.2s;
                cursor: default;

                .slide-wrapper {
                    position: relative;
                    padding-top: 17px;
                    display: flex;
                    flex-direction: column;

                    .img-full-slider {
                        object-fit: cover;
                        width: 100%;
                        max-width: 100%;
                        max-height: 617px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.label {
    margin-left: 1rem;
    background-color: #0d0d0d;
    font-size: 0.6rem;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    top: 0;
    padding: 0.25rem 0.75rem;
    border-radius: 0 0 0.5rem 0.5rem;
}

.popup {
    &-wrapper {
        position: fixed;
        z-index: $zindex-modal-backdrop;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
    }

    &-content {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: $zindex-modal;
        width: 100%;
        max-width: 90vw;
        box-sizing: border-box;

        .swiper-slide {
            padding-top: 0;
            transition: none;

            @include on-mobile {
                height: fit-content;
                padding: 0;
            }
        }

        .swiper-pagination {
            bottom: -24px;
        }

        .image-label-wrp {
            width: 100%;
            box-sizing: border-box;
            position: static;
            background: #222222;
            padding: 24px;
            margin: 0;

            @include on-mobile-portrait {
                padding: 30px 10px 10px;
            }
        }

        .swiper-container {
            padding-bottom: 0;
        }

        .slide-wrapper {
            align-items: center;
            position: relative;
            width: fit-content;
            overflow: hidden;
            margin: 0 auto;
            border-radius: 19px;
        }

        .img-full-slider {
            height: 100%;
            max-height: 617px;
            display: block;

            @include on-mobile {
                max-height: 450px;
            }
        }
    }
}
</style>
