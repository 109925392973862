
  import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
  
  import { IContextMenuItem } from '@/components/context-menu/model';
  
  import ContextMenuSpawner from '@/components/context-menu/ContextMenuSpawner.vue';
  import Button from '@/components/ui/button';
  import DocumentMeta from './DocumentMeta.vue';
  import ButtonGroup from '@/components/ui/button-group';
  import Label from '@/components/ui/label';
  import Icon from '@/components/ui/icon/Icon.vue';
  import { IdentifiedItem, NamedItem } from '@/models/inputs';
  import FindOrCreate from '@/components/context-menu/repeatable/FindOrCreate.vue';
  
  import TagsModule from '@/store/modules/tags/TagsModule';
  
  @Component({
    name: 'DocumentMetaDirections',
    components: { DocumentMeta, Button, ButtonGroup, Label, Icon, ContextMenuSpawner, FindOrCreate },
  })
  export default class DocumentMetaDirections extends Vue {
    @Prop({ default: false }) disabled: boolean;
    @Prop({ default: (): Array<number> => [] }) selected: number[];
  
    public showAddItem = false;
    public items: Array<IContextMenuItem> = [];
  
    public tags: Array<NamedItem & IdentifiedItem> = [];
  
    get selectedTags() {
      return this.tags.filter(tag => this.selected.includes(tag.id));
    }
  
    async created() {
      await this.initPage();
    }
  
    async initPage() {
      await TagsModule.fetchTags().finally(() => {
        this.tags = TagsModule.tags
      });
  
      this.onLoaded();
    }
  
    @Emit('pageLoaded')
    onLoaded() {
      return true;
    }
  
    @Emit('change')
    change(val: number[]) {
      return val;
    }
  
    onSelect(id: number) {
      const selected = this.selected;
      selected.push(id);
  
      this.change(selected);
    }
  
    remove(index: number) {
      const selected = this.selected;
      selected.splice(index, 1);
  
      this.change(selected);
    }
  }
  