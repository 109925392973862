import { render, staticRenderFns } from "./DocumentSummary.vue?vue&type=template&id=69489b34&scoped=true"
import script from "./DocumentSummary.vue?vue&type=script&lang=ts"
export * from "./DocumentSummary.vue?vue&type=script&lang=ts"
import style0 from "./DocumentSummary.vue?vue&type=style&index=0&id=69489b34&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69489b34",
  null
  
)

export default component.exports